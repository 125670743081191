import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { CriteriaForValidityCategory } from 'models';
import { ErrorService, ToastService, CriteriaForValidityService } from 'services';

@autoinject()
export class MooringCriteriaForValidityContainer {
  protected headers: {
    title: string;
  }[] = [];

  constructor(
    private criteriaForValidityService: CriteriaForValidityService,
    private errorService: ErrorService,
    private toast: ToastService,
    private t: I18N
  ) {}

  protected async bind() {
    this.createHeaders();
    await this.getCategories();
  }

  protected categories: CriteriaForValidityCategory[] = [];
  protected async getCategories() {
    try {
      this.categories = await this.criteriaForValidityService.getAll(`?$expand=CriteriaForValidity`);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async onDelete(id: number) {
    try {
      if (id) {
        await this.criteriaForValidityService.delete(id);
        this.toast.showSuccess(this.t.tr('general.deleted'));
        await this.getCategories();
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async onSave(type: CriteriaForValidityCategory) {
    delete type.MooringArticles;
    try {
      if (type.Id) {
        await this.criteriaForValidityService.put(type, type.Id);
      } else {
        await this.criteriaForValidityService.post(type);
      }
      this.toast.showSuccess(this.t.tr('general.saved'));
      await this.getCategories();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected isCreating = false;
  protected editingType: number | undefined = undefined;
  protected setEditingType(id: number) {
    this.isCreating = false;
    this.editingType = id;
  }

  protected setIsCreatingType(isCreating: boolean) {
    this.editingType = undefined;
    this.isCreating = isCreating;
  }

  private createHeaders() {
    this.headers = [
      { title: this.t.tr('general.name') },
      { title: this.t.tr('general.name-en') },
      { title: this.t.tr('general.name-es') },
      { title: this.t.tr('general.actions') },
    ];
  }
}
