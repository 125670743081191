import { autoinject, bindable, observable } from 'aurelia-framework';
import { ErrorService } from 'services/error-service';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';
import { AquacomComponentProduct, MooringArticleType, MooringProductType } from 'models';
import { MooringArticleTypeService, MooringProductTypeService } from 'services';

@autoinject
export class MooringProductTypeCreateEdit {
  @bindable
  @observable
  model: MooringProductType = new MooringProductType();
  modelChanged(model: MooringProductType) {
    if (!model) return;
    this.form = JSON.parse(JSON.stringify(model));
  }

  @bindable
  onSave: (data: { data: MooringProductType }) => void;

  @bindable
  onCanceled: () => void;

  @bindable
  @observable
  visible = false;
  visibleChanged(visible: boolean) {
    if (visible) {
      void this.getArticleTypes();
    }
  }

  protected articleTypes: MooringArticleType[] = [];

  protected form: MooringProductType = {
    Id: undefined,
    Name: undefined,
    NameEn: undefined,
    NameEs: undefined,
  };

  protected aquacomProducts: (AquacomComponentProduct & { PathName: string })[] = [];

  constructor(
    private errorService: ErrorService,
    private validationController: ValidationController,
    private mooringArticleTypeService: MooringArticleTypeService,
    private mooringProductTypeService: MooringProductTypeService,
    private validator: Validator,
    private t: I18N
  ) {}

  protected applyValidationRules() {
    ValidationRules.ensure('Name')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('NameEn')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .on(this.form);
  }

  attached() {
    this.applyValidationRules();
    void this.getAquacomProductTypes();
  }

  protected async getAquacomProductTypes() {
    try {
      const p = await this.mooringProductTypeService.getAquacomTypes();
      this.aquacomProducts = p.map((x) => ({
        ...x,
        PathName: `${x.CategoryName} - ${x.TypeName} - ${x.Name}`,
      }));
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async getArticleTypes() {
    try {
      this.articleTypes = await this.mooringArticleTypeService.getAll();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.form);
    return (await this.validationController.validate({ object: this.form })).valid;
  }

  async save() {
    try {
      const valid = await this.validate();
      if (!valid) return;
      this.onSave?.({ data: this.form });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  cancel() {
    this.onCanceled?.();
  }
}
