import { autoinject, bindable, observable } from 'aurelia-framework';
import { ErrorService } from 'services/error-service';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';
import { CreateCriteriaForValidityCategory, CriteriaForValidityCategory } from 'models';

@autoinject
export class MooringCriteriaForValidityForm {
  @bindable
  @observable
  model: CriteriaForValidityCategory = new CriteriaForValidityCategory();
  modelChanged(model: CriteriaForValidityCategory) {
    if (!model) return;
    this.form = JSON.parse(JSON.stringify(model));
  }

  @bindable
  onSave: (data: { data: CreateCriteriaForValidityCategory }) => void;

  @bindable
  onCanceled: () => void;

  @bindable
  visible = false;

  protected form: CreateCriteriaForValidityCategory = {
    Id: undefined,
    Name: undefined,
    NameEn: undefined,
    NameEs: undefined,
    CriteriaForValidity: [
      {
        Years: 10,
        MaterialFactor: 1.0,
      },
      {
        Years: 15,
        MaterialFactor: 1.0,
      },
    ],
  };

  constructor(
    private errorService: ErrorService,
    private validationController: ValidationController,
    private validator: Validator,
    private t: I18N
  ) {}

  protected applyValidationRules() {
    const rules = ValidationRules.ensure('Name')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('NameEn')
      .required()
      .withMessage(this.t.tr('general.requiredField'));

    this.form.CriteriaForValidity?.forEach?.((item, index) => {
      this.form[`Years_${index}`] = item.Years;
      this.form[`MaterialFactor_${index}`] = item.MaterialFactor;
      rules
        .ensure(`Years_${index}`)
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .min(1)
        .withMessage(this.t.tr('general.requiredField'))
        .ensure(`MaterialFactor_${index}`)
        .required()
        .withMessage(this.t.tr('general.requiredField'))
        .min(0)
        .withMessage(this.t.tr('validation.mustBeGreaterOrEqualToValue', { value: 0 }));
    });
    rules.on(this.form);
  }

  attached() {
    this.applyValidationRules();
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.form);
    return (await this.validationController.validate({ object: this.form })).valid;
  }

  async save() {
    try {
      const valid = await this.validate();
      if (!valid) return;
      this.onSave?.({
        data: {
          CriteriaForValidity: this.form.CriteriaForValidity,
          Id: this.form.Id,
          Name: this.form.Name,
          NameEn: this.form.NameEn,
          NameEs: this.form.NameEs,
        },
      });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  cancel() {
    this.onCanceled?.();
  }
}
